import styled from 'styled-components'

export const StyledTabsSupport = styled.div`
  background-color: #fff;
  h1 {
    display: inline-block;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin: 24px 0 0 34px;
    @media (max-width: 430px) {
      display: none;
    }
  }
  .ant-tabs-nav {
    width: auto;
    height: auto;
    margin: 0 !important;
    background-color: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 24px 34px 12px;
    &:after,
    &:before {
      display: none;
    }
    @media (max-width: 430px) {
      padding: 12px 8px;
    }
    .ant-tabs-nav-wrap {
      width: 100%;
      @media (max-width: 430px) {
        justify-content: start;
      }
    }
    .ant-tabs-nav-operations {
      display: none;
    }
  }
  .ant-tabs-tab {
    border-radius: 2px;
    background-color: white;
    width: 120px;
    height: 37px;
    padding: 10px 21px 9px 25px;
    border: solid 1px #0b4793;
    justify-content: center;
  }
  .ant-tabs-tab:nth-child(2) {
    margin: 0 8px !important;
  }
  .ant-tabs-tab:nth-child(3) {
    margin: 0 !important;
  }
  .ant-tabs-tab-active {
    background-color: #0b4793;
    div {
      color: white !important;
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
`
