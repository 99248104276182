import { Form } from 'antd'
import styled from 'styled-components'

interface FormContent {
  width: string
  padding: string
}

export const StyledSupportForm = styled(Form)`
  background-color: white;
`

export const StyledFormContent = styled.div<FormContent>`
  height: 100vh;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
  @media (max-width: 430px) {
    width: auto;
    padding: 6% 8%;
  }
  .ant-form-item-label {
    margin-top: 25px;
    padding-bottom: 2px !important;
  }
  .ant-select {
    margin-bottom: 0 !important;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
    height: auto;
  }
  .ant-statistic {
    letter-spacing: 0.3px;
  }
  .ant-statistic-title {
    margin: 0;
    color: black;
  }
  .ant-statistic-content {
    height: auto;
    font-size: 16.5px;
    font-weight: 500;
  }
  .ant-form-item-required {
    &::before {
      position: absolute;
      right: -15px;
    }
  }
  .ant-form-item-control-input-content textarea {
    font-size: 0.88rem !important;
    resize: none;
    @media (max-width: 430px) {
      font-size: 0.59rem !important;
    }
  }
`
