import styled from 'styled-components'

interface ContainerButton {
  margin: string
  width: string
}

export const StyledContainerButton = styled.div<ContainerButton>`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  @media (max-width: 430px) {
    width: 66%;
  }
`

export const StyledWhatsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    width: 73%;
    font-size: 12px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a1a;
    margin: 30px 0 0 0 !important;
    display: inline-block !important;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #385cad;
    margin: 0;
  }
  a {
    color: #385cad;
    font-weight: bold;
    font-size: 16px;
  }
`

export const StyledNumberInfo = styled.div`
  width: 251px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
`
