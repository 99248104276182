// Packages
import React from 'react'
import { Form, Row, Col, Input, Select, Statistic } from 'antd'

// Styled Components
import {
  StyledContainerButton,
  StyledWhatsInfo,
  StyledNumberInfo
} from './styledComponents'

// Assets
import { StyledButtonPrimary } from '../Shared/Button/styledComponent'

// Components
import FormSupport from '../FormSupport'
import Loading from '../../components/Shared/Loading'

// Utils
import { validateOneSpace } from '../../utils/forms'

type props = {
  name: string
  bussinessProblem: string
  phone: string
  form: any
  problemCatalog: Array<any>
  store: Array<any>
  typeBussiness: number
  loading: boolean
  disableSend?: boolean
  style?: any
  editableName?: boolean
  useModeloramaSerie?: boolean
  handleSend: () => any
  validateForm?: () => any
  setTypeBussiness: (e: any) => any
}

const Support: React.FC<props> = ({
  name,
  bussinessProblem,
  phone,
  form,
  problemCatalog,
  store,
  typeBussiness,
  loading,
  style,
  handleSend,
  setTypeBussiness
}) => {
  const { Option } = Select
  const { TextArea } = Input

  const displayStore = () => {
    return (
      <Select
        placeholder="Selecciona una opción"
        style={{ width: '100%', marginBottom: '20px' }}
        onChange={
          /* istanbul ignore next */
          (e) => {
            form.setFieldsValue({ ['supportStoreID']: e })
          }
        }
      >
        {store.map((elem, idx): any => {
          return (
            <Option value={idx} key={idx}>
              {elem.name}
            </Option>
          )
        })}
      </Select>
    )
  }

  const displayProblema = () => {
    return (
      <Select
        placeholder="Selecciona una opción"
        style={{ width: '100%', marginBottom: '20px' }}
        onChange={
          /* istanbul ignore next */
          (e) => {
            form.setFieldsValue({ ['supportProblemID']: e })
            setTypeBussiness(e)
          }
        }
      >
        {problemCatalog.map((problem) => {
          return (
            <Option value={problem.StoreProblemID} key={problem.StoreProblemID}>
              {problem.Name}
            </Option>
          )
        })}
      </Select>
    )
  }

  const content = () => {
    return (
      <>
        <Row>
          <Col span={24}>
            <Statistic title="Nombre" value={name} />
          </Col>
        </Row>
        {store.length > 0 && (
          <Row>
            <Col flex="auto">
              <Form.Item
                label="Modelorama"
                name="supportStoreID"
                rules={[
                  {
                    required: true,
                    message: 'Selecciona una opción',
                    type: 'number'
                  }
                ]}
              >
                {displayStore()}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col flex="auto">
            <Form.Item
              label="Problema"
              name="supportProblemID"
              rules={[
                {
                  required: true,
                  message: 'Selecciona una opción',
                  type: 'number'
                }
              ]}
            >
              {displayProblema()}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Form.Item
              label="¿Quieres dejar un comentario?"
              name="supportComentsID"
              rules={[
                {
                  required: true,
                  type: 'string'
                },
                {
                  min: 10,
                  message: 'El comentario debe contener mínimo 10 caracteres'
                },
                {
                  max: 300,
                  message: 'El comentario debe contener máximo 300 caracteres'
                }
              ]}
            >
              <TextArea
                rows={3}
                placeholder="Deja una descripción del problema que se te presentó"
                onChange={(e) => {
                  form.setFields([
                    {
                      name: 'supportComentsID',
                      value:
                        e.target.value[0] === ' '
                          ? ''
                          : e.target.value
                              .split('')
                              .filter(validateOneSpace)
                              .join('')
                    }
                  ])
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <StyledContainerButton margin={'27px 0 0 0'} width={'36%'}>
            <Form.Item shouldUpdate>
              {() => (
                <StyledButtonPrimary
                  type="button"
                  onClick={handleSend}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form.getFieldsError().filter(
                      /* istanbul ignore next */
                      ({ errors }: any) => errors.length
                    ).length
                  }
                >
                  ENVIAR WHATSAPP
                </StyledButtonPrimary>
              )}
            </Form.Item>
          </StyledContainerButton>
        </Row>
        {typeBussiness !== 0 && (
          <Row justify="center">
            <StyledWhatsInfo>
              <h1>Ponte en contacto al teléfono o mándanos un WhatsApp</h1>
              <StyledNumberInfo>
                <h3>{bussinessProblem}</h3>
                <a href={`tel:+52${phone}`}>{`+52 ${phone}`}</a>
              </StyledNumberInfo>
            </StyledWhatsInfo>
          </Row>
        )}
      </>
    )
  }

  return (
    <>
      {!loading && (
        <FormSupport
          content={content()}
          onFinish={handleSend}
          style={style}
          form={form}
        />
      )}
      {loading && <Loading />}
    </>
  )
}

export default Support
