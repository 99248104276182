// Packages
import { FormInstance } from 'antd'

// Filters
export const emailFilter = (value: string): string => {
  return value.replace(/[^0-9a-z._%@+-]/i, '').substr(0, 60)
}

export const onlyDigitsFilter = (
  value: string,
  options?: { maxLength: number }
): string => {
  const regNumber = /^\+?[1-9][0-9]*$/
  if (regNumber.test(value)) {
    return value.substr(0, (options && options.maxLength) || 60)
  }
  return ''
}

export const onlyWordsFilter = (
  value: string,
  options?: { maxLength: number }
): string => {
  return value
    .replace(/[^ a-z´`^¨~á-úà-ùâ-ûä-üñ]/i, '')
    .substr(0, (options && options.maxLength) || 60)
}

export const standardStringFilter = (
  value: string,
  options?: { maxLength: number }
): string => {
  return options && options.maxLength
    ? value
        .replace(/^\s+/, '')
        .replace(/\s+$/, ' ')
        .replace(
          /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
          ''
        )
        .substr(0, options.maxLength)
    : value
        .replace(/^\s+/, '')
        .replace(/\s+$/, ' ')
        .replace(
          /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
          ''
        )
}

export const standardStringFilterWithoutSpace = (
  value: string,
  options?: { maxLength: number }
): string => {
  return value
    .replace(/^\s+/, '')
    .replace(/\s+$/, '')
    .replace(
      /[^ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]/i,
      ''
    )
    .substr(0, (options && options.maxLength) || 60)
}

// Validators
export const emailValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9a-z._%+-]+@[0-9a-z.-]+\.[a-z]{2,}$/i.test(value)
}

export const maxLengthValidator = (
  value: string,
  options?: { maxLength: number }
): string | boolean => {
  return value === '' || value.length <= ((options && options.maxLength) || 60)
}

export const minLengthValidator = (
  value: string,
  options?: { minLength: number }
): string | boolean => {
  return value === '' || value.length >= ((options && options.minLength) || 2)
}

export const onlyDigitsValidator = (value: string): string | boolean => {
  return value === '' || /^[0-9]+$/.test(value)
}

export const onlyWordsValidator = (value: string): string | boolean => {
  return value === '' || /^[ a-z´`^¨~á-úà-ùâ-ûä-üñ]+$/i.test(value)
}

export const requiredValidator = (
  value?: string | boolean
): boolean | string | undefined => {
  return typeof value === 'string' ? value !== '' : value
}

export const standardStringValidator = (value: string): string | boolean => {
  return (
    value === '' ||
    /^[ 0-9a-z´`^¨~á-úà-ùâ-ûä-üñºª@'".:,;!¡¿?=*\-_+<>#$%&\\/()[\]{}]+$/i.test(
      value
    )
  )
}

export const validateInputValue = (
  value: string,
  validations: any[]
): string[] => {
  return validations.reduce(
    (errorMessages, { validator, options, errorMessage }) => {
      if (!validator(value, options)) {
        errorMessages.push(errorMessage)
      }
      return errorMessages
    },
    []
  )
}

export const validateOneSpace = (
  char1: string,
  ind: number,
  arr: any[]
): boolean => {
  if (ind == 0) return true
  if (char1 !== ' ') return true
  const char2 = arr[ind - 1]
  if (char2 !== ' ') return true
  return false
}

export const getFormAllData = (form: FormInstance): any => form.getFieldsValue()
