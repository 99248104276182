// Packages
import React, { useState, useEffect } from 'react'
import { useForm } from 'antd/lib/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

// Styled Components
import { StyledSupportContainer } from './styledComponents'

// Componnets
import TabsHelp from '../../components/TabsHelp'

// Constants
import constants from '../../data/constants'

// Actions
import { requestDataProfile } from '../../redux/ducks/profile'

interface ProfileState {
  profile: any
}

const SupportContainer: React.FC = () => {
  const [form] = useForm()
  const [bussiness, setBussiness] = useState('')
  const [phoneBussiness, setPhoneBussiness] = useState('')
  const [typeBussiness, setTypeBussiness] = useState(0)
  const pageSize = 6
  const { faqs } = constants
  const { user_data, isLoading } = useSelector(
    (state: ProfileState) => state.profile
  )
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({
    faqs,
    totalPage: faqs.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
    pageSize
  })

  useEffect(() => {
    if (isEmpty(user_data)) {
      dispatch(requestDataProfile())
    }
  }, [])

  useEffect(() => {
    switch (typeBussiness) {
      case 1:
        setBussiness('Mi Modelorama')
        setPhoneBussiness('55 35784444')
        break
      case 2:
        setBussiness('BEES Pay')
        setPhoneBussiness('55 86091171')
        break
      default:
        break
    }
  }, [typeBussiness])

  const problemCatalog = [
    { StoreProblemID: 1, Name: 'Problema en Mi Modelorama' },
    { StoreProblemID: 2, Name: 'Problema en BEES Pay' }
  ]

  const handleSendMessage = () => {
    const storeValue = form.getFieldValue('supportStoreID')
    const problemValue = form.getFieldValue('supportProblemID') - 1
    const comentValue = form.getFieldValue('supportComentsID')

    const whatsMessage = `Hola soy ${user_data?.getProfile.name} ${
      user_data?.getProfile.middleName
    } del modelorama ${
      user_data?.getProfile.stores[storeValue].name
    }, tengo un ${problemCatalog[
      problemValue
    ].Name.toLowerCase()}. El problema es el siguiente: ${comentValue.trimEnd()}`

    window.open(
      `https://wa.me/52${phoneBussiness.replaceAll(
        ' ',
        ''
      )}?text=${encodeURIComponent(whatsMessage)}`,
      '_blank'
    )

    form.resetFields()
    setTypeBussiness(0)
  }

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize
    })
  }

  return (
    <>
      {!isEmpty(user_data) && (
        <StyledSupportContainer>
          <TabsHelp
            name={
              user_data
                ? `${user_data.getProfile.name} ${user_data.getProfile.middleName}`
                : ''
            }
            bussinessProblem={bussiness}
            phone={phoneBussiness}
            form={form}
            problemCatalog={problemCatalog}
            store={user_data ? user_data.getProfile.stores : []}
            typeBussiness={typeBussiness}
            loading={isLoading}
            handleSend={handleSendMessage}
            setTypeBussiness={setTypeBussiness}
            handlePagination={handlePagination}
            pagination={pagination}
          />
        </StyledSupportContainer>
      )}
    </>
  )
}

export default SupportContainer
