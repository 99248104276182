// Packages
import React from 'react'
import { Tabs } from 'antd'

// Styled Components
import { StyledTabsSupport } from './styledComponents'

// Components
import Support from '../Support'

type props = {
  name: string
  bussinessProblem: string
  phone: string
  form: any
  problemCatalog: Array<any>
  store: Array<any>
  typeBussiness: number
  loading: boolean
  disableSend?: boolean
  handleSend: () => any
  validateForm?: () => any
  setTypeBussiness: any
  handlePagination: (page: number) => any
  pagination: {
    faqs: { question: string; answer: string }[]
    totalPage: number
    current: number
    minIndex: number
    maxIndex: number
    pageSize: number
  }
}

const TabsHelp: React.FC<props> = ({ ...props }) => {
  const { TabPane } = Tabs

  return (
    <StyledTabsSupport>
      <h1>Ayuda</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Asistencia" key="3">
          <Support {...props} />
        </TabPane>
      </Tabs>
    </StyledTabsSupport>
  )
}

export default TabsHelp
