// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../components/SEO'
import SupportContainer from '../../containers/SupportContainer'
import LayoutMain from '../../layout'

const Support: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Ayuda" />
      <WithPrivateRoute component={SupportContainer} />
    </LayoutMain>
  )
}

export default Support
