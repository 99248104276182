// Packages
import React from 'react'
import { FormInstance } from 'antd'

// Styled Components
import { StyledSupportForm, StyledFormContent } from './styledComponents'

type props = {
  content: any
  style?: any
  onFinish: () => any
  form?: FormInstance
}

const FormSupport: React.FC<props> = ({ content, onFinish, form, style }) => {
  return (
    <StyledSupportForm layout="vertical" onFinish={onFinish} form={form}>
      <StyledFormContent style={style} width={'590px'} padding={'24px 34px'}>
        {content}
      </StyledFormContent>
    </StyledSupportForm>
  )
}

export default FormSupport
